import 'swiper/css';
import 'swiper/css/pagination';
import './css/main.css';

import Swiper, { Navigation, Pagination } from 'swiper';



// ------------------------------  Swiper ------------------------------



Swiper.use([Navigation, Pagination]); 

var swipercont = document.getElementById("swiper-gallery");
if (typeof(swipercont) != 'undefined' && swipercont != null ) {
    console.log("Swiper start");
    var swiperlength = document.getElementById("swiper-gallery").getAttribute("data-loopnum");
    var swiper = new Swiper('.swiper-container', {
        loop: true,
        init: false,
        preloadImages: false,
        slidesPerView: 'auto',
        watchSlidesVisibility: true,
        loopedSlides: swiperlength,
            centeredSlides: false,
            spaceBetween: 0,
        // If we need pagination
        // pagination: {
        //     el: '.swiper-pagination',
        //     clickable: true,
        // },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
        
        });

        swiper.on('afterInit', function () {    
            // var currentslide = swiper.realIndex;
            setTimeout(function() {showContent(swiper.realIndex)}, 600);
            
    
          });

          swiper.init();

          swiper.on('slideChange', function () {

            setTimeout(function() {showContent(swiper.realIndex)}, 600);
            
          });

          function showContent(currentslide) {
            var id = swiper.slides[currentslide].getAttribute("data-id");
            var allcontent = document.getElementsByClassName("content");
            var j;
            for (j=0; j < allcontent.length; j++) {
                allcontent[j].classList.add("opacity-0");
            };
            
            var projectcontent = document.getElementsByClassName("content-" + id);
            var i;
            for (i=0; i < projectcontent.length; i++) {
                projectcontent[i].classList.remove("opacity-0");
            }

            
          };

} else false;